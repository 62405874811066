import { dispatch } from '@/store'
import { userAct } from '@/store/user.store'
import type { FormChangePassword, FormRedeem } from '@/types/form'
import { createFormData } from '@/utils'

import axios from '../axios'
import { tryCatch } from '../catch'

export class UserService {
  static async changePassword(data: FormChangePassword) {
    try {
      const [formData, headers] = createFormData({ newPassword: data.password })
      const response = await axios.post(`/v1/users/changepassword`, formData, { headers })
      return response.data
    } catch (error) {
      tryCatch('`UserService.getBalance`', error)
    }
  }

  static async getBalance() {
    try {
      const response = await axios.get(`/v1/user/refresh_balance`)
      if (response.data.status) {
        dispatch(userAct.setProfile(response.data.data))
      }
    } catch (error) {
      tryCatch('`UserService.getBalance`', error)
    }
  }

  static async refresh() {
    try {
      const response = await axios.get(`/v1/user/refresh`)
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`UserService.refresh`', error)
    }
  }

  static async deposit(amount: number) {
    try {
      const [formData, headers] = createFormData({ amount })
      const response = await axios.post(`/v1/payment/qrcode`, formData, { headers })
      return response.data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async depositRefresh() {
    try {
      const response = await axios.get(`/v1/user/refresh`)
      return response.data?.status && response.data?.message == 'FOUND_DEPOSIT'
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async depositHistory() {
    try {
      const response = await axios.get(`/v1/history/deposit`)
      if (response.data?.status) {
        return response.data.data
      }
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async withdraw() {
    try {
      const response = await axios.post(`/v1/users/withdraw`)
      return response.data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async withdrawAllGame() {
    try {
      const response = await axios.post(`/v1/game/withdraw`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return response.data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async withdrawHistory() {
    try {
      const response = await axios.get(`/v1/history/withdraw`)
      if (response.data?.status) {
        return response.data.data
      }
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async redeemCode(data: FormRedeem) {
    try {
      const [formData, headers] = createFormData(data)
      const response = await axios.post(`/v1/users/redeemcode`, formData, { headers })
      return response.data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async cashback() {
    try {
      const response = await axios.post(`/v1/users/cashback`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async claim() {
    try {
      const response = await axios.post(`/v1/users/affiliate`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async playUFA() {
    try {
      const { data } = await axios.post(`/v1/game/ufabet`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }

  static async playBetflik() {
    try {
      const { data } = await axios.post(`/v1/game/betflik`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
    } catch (error) {
      tryCatch('`UserService.redeemCode`', error)
    }
  }
}
