import { createFormData } from '@/utils'

import axios from '../axios'
import { tryCatch } from '../catch'

export class CommonService {
  static async contactAdmin() {
    try {
      const response = await axios.get(`/v1/contact`)
      if (response.data?.status) {
        location.assign(response.data.url)
      }
    } catch (error) {
      tryCatch('`CommonService.getPromotions`', error)
    }
  }

  static async getPromotions() {
    try {
      const response = await axios.get(`/v1/promotion/all`)
      if (response.data) {
        return response.data?.data || []
      }
    } catch (error) {
      tryCatch('`CommonService.getPromotions`', error)
    }
  }

  static async getPromotionBy(id: string) {
    try {
      const response = await axios.get(`/v1/promotion/${id}`)
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`CommonService.getPromotions`', error)
    }
  }

  static async acceptPromotion(id: string) {
    try {
      const [formData, headers] = createFormData({ id })
      const response = await axios.post(`/v1/promotion/accepts`, formData, { headers })
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`CommonService.getPromotions`', error)
    }
  }

  static async getGames() {
    try {
      const response = await axios.get<any[]>(`/games_share/provider.YedHee`)
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`CommonService.getGames`', error)
    }
  }

  static async getGamesBy(provider: string) {
    try {
      const response = await axios.get<any[]>(`/games_share/${provider}.YedHee`)
      if (response.data) {
        return response.data
      }
    } catch (error) {
      tryCatch('`CommonService.getGames`', error)
    }
  }
}
