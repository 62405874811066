'use client'

import { useCallback } from 'react'

import { configs } from '@/constants'
import { useLoader, useMounted } from '@/hooks'
import { AuthService } from '@/services'
import { useDispatch } from '@/store'
import { userAct } from '@/store/user.store'
import { cookie } from '@/utils/storage'

export default function Bootstrap() {
  // __STATE's
  const dispatch = useDispatch()
  const loader = useLoader()

  // __FUNCTION's
  const starter = useCallback(async () => {
    const accessToken = cookie.get(configs.APP_AUTH_ACCESS)
    if (accessToken) {
      const profile = cookie.get(configs.APP_USER_INFO, 1)
      if (profile) {
        dispatch(userAct.setProfile(profile))
      }

      await AuthService.profile()
    } else {
      AuthService.signOut()
    }

    loader.off()
  }, [])

  // __EFFECT's
  useMounted(() => {
    starter()
  })

  // __RENDER
  return null
}
